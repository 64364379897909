<template>
  <v-app>
    <fusion-appbar
      :logoSrc="logoSrc"
      :hasDrawer="$auth.isAuthenticated"
      :isLoading="$auth.loading"
      @drawerClicked="drawerToggle()"
      @navigateHome="navigateHome()"
      data-cy="appBar"
    >
      <span :class="{ 'application-burger': $auth.isAuthenticated && $vuetify.breakpoint.lgAndDown }" slot="burger">
        <v-app-bar-nav-icon
          v-if="$auth.isAuthenticated && $vuetify.breakpoint.lgAndDown"
          @click.stop="drawerToggle()"
          class="mx-0 text"
          x-large
          :color="$vuetify.theme.currentTheme.textPrimary"
          data-cy="hamburgerButton"
        />
      </span>
      <div slot="left-toolbar"></div>
      <div slot="right-toolbar">
        <fusion-authenticator />
      </div>
    </fusion-appbar>
    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="drawer.open"
      :clipped="drawerClipped"
      :permanent="drawerPermanent"
      :mini-variant="drawerMini"
      color="#f2f2f2"
      mini-variant-width="68"
      width="240"
      height="100%"
      app
    >
      <fusion-sidebar :routes="router"></fusion-sidebar>
      <template v-if="drawer.open" v-slot:append>
        <v-list-item @click.stop="openFeedback" data-cy="feedbackIcon">
          <v-list-item-action>
            <v-icon>mdi-bullhorn</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.feedback.feedback') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <span class="footer" v-if="!drawerMini">
          <span class="footer--logo">
            &copy; {{ new Date().getFullYear() }}
            {{ $t('app.copyright') }}
          </span>
          <span class="footer--version">v{{ removePreRelease($store.state.releaseVersion) }}</span>
        </span>
      </template>
    </v-navigation-drawer>
    <fusion-feedback :open="dialogs.feedback" @close="dialogs.feedback = false" />
    <v-main class="background-image">
      <v-container class="ma-0 pa-0" fluid>
        <router-view class="main-content" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="js">
import { mixins } from '@fusion/saas-components'

export default {
  mixins: [mixins.drawer],
  data: () => {
    return {
      logoSrc: `${process.env.VUE_APP_PUBLIC_PATH}img/tsi_link_logo.svg`,
      dialogs: {
        feedback: false
      }
    }
  },
  created () {
    document.title = this.$t('app.documentTitle')
  },
  computed: {
    router () {
      return this.$router?.options?.routes
    },
    user () {
      let user = {}
      if (this.$auth?.user?.email) {
        user = this.$auth.user
      }
      return user
    }
  },
  methods: {
    navigateHome () {
      window.open('/')
    },
    async login () {
      await this.$auth.loginWithRedirect()
    },
    openFeedback () {
      this.dialogs.feedback = true
    },
    removePreRelease (version) {
      if (!version) return version
      return version.split('-')[0]
    }
  }
}
</script>

<style lang="scss">
.main-content {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.application-burger {
  background-color: #fff;
  height: 100%;
  flex: inherit;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.footer {
  opacity: 0.3;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-height: 37px;
}

main {
  background: var(--tsi-bg--gradient);
}
</style>
