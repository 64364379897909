<template>
  <div>
    <h2 data-cy="privateHomePlaceHolder">{{ $t('privateHome.title') }}</h2>
    <fusion-reactivate-account :open="dialogs.reactivateAccount" @close="this.closeReactivateAccount"></fusion-reactivate-account>
  </div>
</template>

<script lang="js">
import {
  accountExists,
  emailVerified,
  isAuthorized
} from '@fusion/auth'
import { mixins } from '@fusion/saas-components'

export default {
  name: 'PrivateHomePage',
  mixins: [isAuthorized, accountExists, emailVerified, mixins.reactivateAccount]
}
</script>

<style scoped lang="scss"></style>
