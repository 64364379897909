<template>
  <fusion-public-page />
</template>

<script lang="js">
import { isNotAuthorized } from '@fusion/auth'

export default {
  name: 'PublicHomePage',
  mixins: [isNotAuthorized]
}
</script>

<style scoped lang="scss"></style>
