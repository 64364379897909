<template>
  <fusion-help-center />
</template>

<script lang="js">
export default {
  name: 'HelpPage'
}
</script>

<style scoped lang="scss">
.help-center-card {
  position: inherit;
  height: 82vh;
}
</style>
