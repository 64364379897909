var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "fusion-appbar",
        {
          attrs: {
            logoSrc: _vm.logoSrc,
            hasDrawer: _vm.$auth.isAuthenticated,
            isLoading: _vm.$auth.loading,
            "data-cy": "appBar",
          },
          on: {
            drawerClicked: function ($event) {
              return _vm.drawerToggle()
            },
            navigateHome: function ($event) {
              return _vm.navigateHome()
            },
          },
        },
        [
          _c(
            "span",
            {
              class: {
                "application-burger":
                  _vm.$auth.isAuthenticated &&
                  _vm.$vuetify.breakpoint.lgAndDown,
              },
              attrs: { slot: "burger" },
              slot: "burger",
            },
            [
              _vm.$auth.isAuthenticated && _vm.$vuetify.breakpoint.lgAndDown
                ? _c("v-app-bar-nav-icon", {
                    staticClass: "mx-0 text",
                    attrs: {
                      "x-large": "",
                      color: _vm.$vuetify.theme.currentTheme.textPrimary,
                      "data-cy": "hamburgerButton",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.drawerToggle()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { attrs: { slot: "left-toolbar" }, slot: "left-toolbar" }),
          _c(
            "div",
            { attrs: { slot: "right-toolbar" }, slot: "right-toolbar" },
            [_c("fusion-authenticator")],
            1
          ),
        ]
      ),
      _vm.$auth.isAuthenticated
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                clipped: _vm.drawerClipped,
                permanent: _vm.drawerPermanent,
                "mini-variant": _vm.drawerMini,
                color: "#f2f2f2",
                "mini-variant-width": "68",
                width: "240",
                height: "100%",
                app: "",
              },
              scopedSlots: _vm._u(
                [
                  _vm.drawer.open
                    ? {
                        key: "append",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item",
                              {
                                attrs: { "data-cy": "feedbackIcon" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openFeedback.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-action",
                                  [_c("v-icon", [_vm._v("mdi-bullhorn")])],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(_vm.$t("app.feedback.feedback"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                            !_vm.drawerMini
                              ? _c("span", { staticClass: "footer" }, [
                                  _c("span", { staticClass: "footer--logo" }, [
                                    _vm._v(
                                      " © " +
                                        _vm._s(new Date().getFullYear()) +
                                        " " +
                                        _vm._s(_vm.$t("app.copyright")) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "footer--version" },
                                    [
                                      _vm._v(
                                        "v" +
                                          _vm._s(
                                            _vm.removePreRelease(
                                              _vm.$store.state.releaseVersion
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.drawer.open,
                callback: function ($$v) {
                  _vm.$set(_vm.drawer, "open", $$v)
                },
                expression: "drawer.open",
              },
            },
            [_c("fusion-sidebar", { attrs: { routes: _vm.router } })],
            1
          )
        : _vm._e(),
      _c("fusion-feedback", {
        attrs: { open: _vm.dialogs.feedback },
        on: {
          close: function ($event) {
            _vm.dialogs.feedback = false
          },
        },
      }),
      _c(
        "v-main",
        { staticClass: "background-image" },
        [
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [_c("router-view", { staticClass: "main-content" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }